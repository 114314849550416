@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

.navDropdown {
  display: none;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.navDropdownButton:hover .navDropdown {
  display: flex;
  opacity: 1;
  transform: translateY(0);
}

.hide {
  display: none;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: rgba(128, 128, 128, 0.125);
}

*::-webkit-scrollbar-thumb {
  background-color: #ff7a00; /* Set color of scrollbar thumb */
  border-radius: 100px;
}

@layer components {
  /* Buttons */
  .btnPrimary {
    @apply px-5 py-2 text-sm font-medium flex items-center justify-center gap-2 bg-secondary whitespace-nowrap text-center capitalize text-white rounded-md cursor-pointer transition-all;
  }
  .btnPrimary:hover {
    @apply opacity-90;
  }
  .btnPrimary:disabled {
    @apply opacity-50 cursor-not-allowed;
  }
  .btnSecondary {
    @apply px-4 py-2 bg-secondary/80 font-medium text-center capitalize shadow-md shadow-gray-300 rounded cursor-pointer;
  }
  .btnSecondary:active:not(:disabled) {
    @apply scale-105 transition-all;
  }
  .btnSecondary:hover:not(:disabled) {
    @apply opacity-90;
  }
  .btnSecondary:disabled {
    @apply bg-[#aaaaaa];
  }
  .btnWhite {
    @apply font-medium text-secondary text-sm flex items-center justify-center gap-2 cursor-pointer py-1 px-2 shadow-md bg-white text-center rounded-full transition-all;
  }
  .btnWhite:disabled {
    @apply cursor-not-allowed opacity-50;
  }
  .serviceButton {
    @apply text-center capitalize border-gray-200 p-2 border-b transition-all;
  }
  .serviceButton:hover {
    @apply bg-white shadow-lg border-white rounded-lg;
  }

  /* Dark Mode */
  .light {
    @apply bg-gray-50 text-black;
  }
  .dark {
    @apply bg-dark text-white;
  }

  /* Heading */
  .elementHeading1 {
    @apply text-3xl lg:text-4xl font-bold;
  }
  .elementHeading2 {
    @apply text-2xl lg:text-3xl font-bold;
  }
  .elementHeading3 {
    @apply text-xl lg:text-2xl font-bold;
  }
  .elementHeading4 {
    @apply text-lg lg:text-xl font-bold;
  }
  .elementHeading5 {
    @apply lg:text-lg font-bold;
  }

  /* Links */
  .pageLink {
    @apply p-2 flex items-center w-full outline-none whitespace-nowrap;
  }
  .pageLink:hover {
    @apply no-underline bg-gray-100/10;
  }

  /* Table */
  .tableHead {
    @apply px-3 py-2 text-sm text-left whitespace-nowrap font-semibold text-gray-900 dark:text-white/80 uppercase tracking-wider;
  }
  .tableBody {
    @apply bg-white divide-y divide-gray-200 h-full overflow-y-scroll;
  }

  /* Input Fields */
  .inputLabel {
    @apply block text-gray-900 text-sm font-semibold capitalize;
  }
  .inputField {
    @apply w-full px-4 py-3 border rounded outline-none;
  }
  .inputField:focus {
    @apply ring-2 ring-yellow-500;
  }

  /* Cards */
  .card {
    @apply dark:bg-dark bg-white shadow-xl dark:shadow-2xl shadow-gray-200 dark:shadow-black/30 border dark:border-dark p-5 lg:p-7 rounded-lg;
  }

  .pagination {
    @apply disabled:opacity-50 disabled:cursor-not-allowed hover:bg-dark/10 transition-all dark:hover:bg-white/20 rounded-full p-1;
  }
  .apiLink {
    @apply transition-all;
  }
  .apiLink:hover {
    @apply text-blue-600 underline transition-all;
  }

  /* Icons */
  .editIcon {
    @apply w-4 h-5 cursor-pointer text-gray-500 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-500 transition-all;
  }
  .deleteIcon {
    @apply w-4 h-5 cursor-pointer text-gray-500 dark:text-gray-400 hover:text-red-600 dark:hover:text-red-500 transition-all;
  }
}

.table-container {
  max-height: 60vh;
  overflow-y: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.column-header {
  padding: 8px 16px;
  font-weight: bold;
}

.table-content {
  margin-top: 36px; /* Adjust this value to match the height of the sticky header */
}
.css-12u49z-MuiDataGrid-root {
  border: none !important;
}
